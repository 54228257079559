// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/Elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-en-events-js": () => import("./../../../src/pages/en/events.js" /* webpackChunkName: "component---src-pages-en-events-js" */),
  "component---src-pages-en-gallery-js": () => import("./../../../src/pages/en/gallery.js" /* webpackChunkName: "component---src-pages-en-gallery-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-our-wines-js": () => import("./../../../src/pages/en/our-wines.js" /* webpackChunkName: "component---src-pages-en-our-wines-js" */),
  "component---src-pages-en-showroom-budapest-js": () => import("./../../../src/pages/en/showroom-budapest.js" /* webpackChunkName: "component---src-pages-en-showroom-budapest-js" */),
  "component---src-pages-en-wine-safe-deposit-vault-js": () => import("./../../../src/pages/en/wine-safe-deposit-vault.js" /* webpackChunkName: "component---src-pages-en-wine-safe-deposit-vault-js" */),
  "component---src-pages-generic-js": () => import("./../../../src/pages/Generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-hu-bemutatoterem-budapest-js": () => import("./../../../src/pages/hu/bemutatoterem-budapest.js" /* webpackChunkName: "component---src-pages-hu-bemutatoterem-budapest-js" */),
  "component---src-pages-hu-boraink-js": () => import("./../../../src/pages/hu/boraink.js" /* webpackChunkName: "component---src-pages-hu-boraink-js" */),
  "component---src-pages-hu-bortrezor-js": () => import("./../../../src/pages/hu/bortrezor.js" /* webpackChunkName: "component---src-pages-hu-bortrezor-js" */),
  "component---src-pages-hu-galeria-js": () => import("./../../../src/pages/hu/galeria.js" /* webpackChunkName: "component---src-pages-hu-galeria-js" */),
  "component---src-pages-hu-index-js": () => import("./../../../src/pages/hu/index.js" /* webpackChunkName: "component---src-pages-hu-index-js" */),
  "component---src-pages-hu-palyazatok-js": () => import("./../../../src/pages/hu/palyazatok.js" /* webpackChunkName: "component---src-pages-hu-palyazatok-js" */),
  "component---src-pages-hu-rendezvenyek-js": () => import("./../../../src/pages/hu/rendezvenyek.js" /* webpackChunkName: "component---src-pages-hu-rendezvenyek-js" */),
  "component---src-pages-hu-szepasszony-volgy-js": () => import("./../../../src/pages/hu/szepasszony-volgy.js" /* webpackChunkName: "component---src-pages-hu-szepasszony-volgy-js" */)
}

